import {Controller} from "stimulus"

export default class extends Controller {
  initialize() {
    $('.site-bars').on('click', function(event) {
      event.preventDefault()
      var menu = $('.site-navigation-mobile')
      if (menu.hasClass('opened')) {
        menu.removeClass('opened')
        menu.addClass('closed')
        $(this).removeClass('is-active')
      } else {
        $(this).addClass('is-active')
        menu.removeClass('closed')
        menu.addClass('opened')
      }
    })
  }
}